<template>
  <div>
<!--    <div class="crumbs">-->
<!--      <el-breadcrumb separator="/">-->
<!--        <el-breadcrumb-item>-->
<!--          <i class="el-icon-lx-cascades"></i> 角色管理-->
<!--        </el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--    </div>-->
    <div style="padding: 20px">

      <div class="handle-box">
        <el-button type="primary" class="handle-del mr10" @click="addVisible = true">新增角色</el-button>

        <el-input v-model="query.roleName" placeholder="角色名称" class="handle-input mr10"></el-input>

        <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>

        <el-button type="info" icon="Search" @click="reset">重置</el-button>

      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <!-- 展开列 -->
        <el-table-column type="expand" class="bdbottom">
          <template #default="scope">
            <el-tag style="margin: 20px" v-for="(item) in scope.row.menus" :key="item.id">{{ item.words }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>


        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <!--            <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑
                        </el-button>-->
            <!--编辑按钮-->
            <el-button @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <!--删除按钮-->
            <el-button @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <!--分配角色按钮-->
            <!--            <el-tooltip class="item" effect="dark" :content="'为'+ scope.row.name + '分配权限'" :enterable="false"
                                    placement="top">
                          <el-button type="warning" icon="el-icon-setting" size="small" circle></el-button>
                        </el-tooltip>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改角色" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">


        <el-form-item prop="name" label="角色名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
        <!-- {{form.menus}} -->
          <div class="block">
            <el-cascader
                v-model="form.menusIds"
                :options="menus"
                :props="{ multiple: true,label:'words',value:'id'}"
                clearable></el-cascader>
          </div>
        </el-form-item>

        <el-form-item label="添加时间">
          <el-input disabled v-model="form.createTime"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="qx">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 新增弹出框框 -->
    <el-dialog title="新增角色" v-model="addVisible" width="30%">
      <el-form :rules="rules" ref="ruleForm" :model="addForm" label-width="100px">
        <el-form-item prop="name" label="角色名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <div class="block">
            <el-cascader
                v-model="menusIds"
                :options="menus"
                :props="{ multiple: true,label:'words',value:'id'}"
                clearable></el-cascader>
          </div>
        </el-form-item>

      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd('ruleForm')">确 定</el-button>
                </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import {list,edit,menus ,add,del} from "@/api/role";

export default {
  name: "Menus",
  data() {
    return {
      query: {
        roleName: "",
        regionId: "",
        campusId: "",
        pageIndex: 1,
        pageSize: 10
      },
      rules: {
        name: [
          {required: true, message: '请输入角色名', trigger: 'blur'},
        ]
      },
      menusIds:[],
      menus: [],
      regionList: [],
      campusList: [],
      campusList1: [],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      addForm: {

      },
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
    menus().then(res => {
      if (res.code == 200) {
        this.menus = res.data
      }
    })
  },
  methods: {
    // 获取数据
    getData() {
      // 表格数据
      list(this.query).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      }).catch(() => {
      })
    },

    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex = 1
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除: " + row.name + " 吗?", "提示", {
        type: "warning"
      }).then(() => {
        del(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
          } else {
            this.$message.error("删除失败");
          }
        })

      }).catch(() => {
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      console.log(row);
      this.idx = index;
      this.form = row;
      console.log(this.menus);
      console.log(this.menusIds);
      this.editVisible = true;
    },
    //取消编辑
    qx() {
      this.editVisible = false;
      this.$message.info(`您取消了修改`);
    },
    // 保存编辑
    saveEdit() {
      const  array = JSON.parse(JSON.stringify(this.form.menusIds))
      this.form.menuIds =""
      for (let index in array) {
        console.log(array[index][1]);
        this.form.menuIds += array[index][1]+","
      }
      delete  this.form.menusIds
      delete  this.form.menus
      delete  this.form.isDelete
      delete  this.form.createTime
      edit(JSON.parse(JSON.stringify(this.form))).then(res => {
        this.editVisible = false;
        if (res.code == 200) {
          if (res.data.data > 0) {
            this.getData()
            this.$message.success(`修改角色: ${this.form.name} 成功`);
          } else {
            this.$message.error(`修改角色: ${this.form.name} 失败`);
          }
        } else {
          this.$message.error(`修改角色: ${this.form.name} 失败`);
        }
      })

    },

    /**
     * 新增
     */
    saveAdd(formName) {
      const  array = JSON.parse(JSON.stringify(this.menusIds))
      this.addForm.menuIds =""
      for (let index in array) {
        console.log(array[index][1]);
        this.addForm.menuIds += array[index][1]+","
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add(JSON.parse(JSON.stringify(this.addForm))).then(res => {
            this.addVisible = false;
            console.log(res);
            if (res.code == 200) {
              if (res.data > 0) {
                this.getData()
                this.$message.success(`新增角色: ${this.addForm.name} 成功`);
              } else {
                this.$message.error(`新增角色: ${this.addForm.name} 失败`);
              }
            } else {
              this.$message.error(`新增角色: ${this.addForm.name} 失败`);
            }
          })
        }
      })
    },

    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },

    // 编辑 选中城市  渲染校区
    selectCity2(val) {
      this.form.campusid = null
      this.addForm.campusid = null
      this.campusList1 = JSON.parse(JSON.stringify(this.regionList[val - 1])).campuses;
    },
    // 重置
    reset() {
      this.query.regionId = ""
      this.query.campusId = ""
      this.query.type = ""
      this.query.roleName = ""
      this.query.pageIndex = 1
      this.getData()
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.bdbottom {
  border-bottom: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}
</style>
