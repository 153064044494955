import request from '../utils/request';

/**
 * 菜单列表
 * @param query
 * @returns {AxiosPromise}
 */
export const list = (query) => {
    return request({
        url: '/sys/role/list',
        method: 'get',
        params:query
    });
};
/**
 *  菜单列表
 * @returns {AxiosPromise}
 */
export const menus = () => {
    return request({
        url: '/sys/role/menus',
        method: 'get',
    });
};
/**
 * 新增角色
 * @param addForm
 * @returns {AxiosPromise}
 */
export const add = (addForm) => {
    return request({
        url: '/sys/role/add',
        params:addForm
    });
};
export const edit = (addForm) => {
    return request({
        url: '/sys/role/edit',
        params:addForm
    });
};

export const del = (id) => {
    return request({
        url: '/sys/role/del',
        params: {id}
    });
};

